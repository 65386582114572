import request from '@/utils/request';
import {
  product,
} from "@/apis/index"
import {
  storeDecorator
} from "@/utils/decorator"

export default storeDecorator({
  name: "product",
  state: {
    enabledProducts: [],
    upgradeProducts: [],
  },
  actions: {
    getProducts(_, params) {
      return request.get(product, params)
    },
    getProduct(_, payload) {
      const url = product + "/" + payload.id
      return request.get(url, payload.params)
    },
    createProduct(_, payload) {
      return request.post(product, payload.params)
    },
    updateProduct(_, payload) {
      const url = product + "/" + payload.id
      return request.put(url, payload.params)
    },
    getEnabledProducts({ commit, state }) {
      return new Promise((resolve, reject) => {
        if (state.enabledProducts.length) {
          resolve(state.enabledProducts)
        } else {
          const params = {
            SkipCount: 0,
            MaxResultCount: 1000,
          }
          request.get(product, params).then(res => {
            if (res.status == 200) {
              const products = res.data.items
              commit("changeEnabledProducts", products)
              resolve(products)
            }
          })
        }
      })
    },
    getUpgradeProducts({ commit, state }) {
      return new Promise((resolve, reject) => {
        if (state.upgradeProducts.length) {
          resolve(state.upgradeProducts)
        } else {
          const params = {
            SkipCount: 0,
            MaxResultCount: 1000,
            IsUpgrade: true,
          }
          request.get(product, params).then(res => {
            if (res.status == 200) {
              const products = res.data.items
              commit("changeUpgradeProducts", products)
              resolve(products)
            }
          })
        }
      })
    }
  },
})