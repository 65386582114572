import Vue from 'vue';
import axios from 'axios';

export const dateFormat = function (date, fmt) {
  let o = {
    "M+": date.getMonth() + 1, //月份   
    "d+": date.getDate(), //日   
    "h+": date.getHours(), //小时   
    "m+": date.getMinutes(), //分   
    "s+": date.getSeconds(), //秒   
    "q+": Math.floor((date.getMonth() + 3) / 3), //季度   
    "S": date.getMilliseconds() //毫秒   
  };
  if (/(y+)/.test(fmt)) {
    fmt = fmt.replace(RegExp.$1, (date.getFullYear() + "").substr(4 - RegExp.$1.length));
  }
  for (let k in o) {
    if (new RegExp("(" + k + ")").test(fmt)) {
      fmt = fmt.replace(RegExp.$1, (RegExp.$1.length == 1) ? (o[k]) : (("00" + o[k]).substr(("" + o[k]).length)));
    }
  }
  return fmt;
}

export const formatterISOFromTimestamp = (date) => {
  let result = ""
  try {
    result = dateFormat(new Date(date), "yyyy-MM-ddThh:mm:ss.S")
  } catch (err) {

  }
  return result
}

export const formatterDateFromUnix = (unixtime) => {
  if (unixtime) {
    return new Date(unixtime * 1000)
  } else {
    return unixtime
  }
}

export const firstLetterUpperCase = (origin) => {
  let result = ""
  if (origin) {
    result = origin + ""
    result = result.slice(0, 1).toUpperCase() + result.slice(1)
  }
  return result
}

export const formatterMoney = (value) => {
  if (!isNaN(value)) {
    return "￥" + formatterNumber((value / 100).toFixed(2))
  } else {
    return ""
  }
}

export const formatterPrice = (value) => {
  if (!isNaN(value)) {
    return formatterNumber((value / 100).toFixed(2))
  } else {
    return ""
  }
}

export const formatterRatio = (value) => {
  if (!isNaN(value)) {
    return (value * 100).toFixed(1) + "%"
  } else {
    return value
  }
}

export const formatterFullRatio = (value) => {
  if (!isNaN(value)) {
    return (value / 10).toFixed(1) + "%"
  } else {
    return value
  }
}

export const formatterRate = function (value) {
  if (value !== null && value !== undefined) {
    return (value > 0 ? "+" : "") + (value * 100).toFixed(1) + "%"
  } else {
    return "无数据"
  }
}

export const formatterNumber = (value) => {
  if (!isNaN(value)) {
    return value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')
  } else if (value === null || value === "null") {
    return "-"
  }
}

export const formatterAmount = (value) => {
  if (!isNaN(value)) {
    return Math.round(value * 100)
  } else {
    return 0
  }
}

export const formatterMoneyPlain = (value) => {
  if (!isNaN(value)) {
    return (value / 100).toFixed(2)
  } else {
    return ""
  }
}

export const ageCalculator = (timestamp) => {
  let yearMonthDayObj = ageFormatter(new Date(timestamp))
  let result = ""
  if (yearMonthDayObj.year) result += yearMonthDayObj.year + "Y "
  if (yearMonthDayObj.month) result += yearMonthDayObj.month + "M "
  if (yearMonthDayObj.day) result += yearMonthDayObj.day + "D"
  if (result) {
    return result
  } else {
    return "不足日"
  }
}

export const ageFormatter = (birthday) => {
  const now = new Date()
  let year = now.getFullYear() - birthday.getFullYear()
  let month = now.getMonth() - birthday.getMonth()
  let day = now.getDate() - birthday.getDate()
  if (day < 0) {
    month--
    let nowToStart = now.getDate()
    let birthdayToEnd = new Date(birthday.getFullYear(), birthday.getMonth() + 1, 0).getDate() - birthday.getDate()
    day = nowToStart + birthdayToEnd
  }
  if (month < 0) {
    year--
    month += 12
  }
  return {
    year,
    month,
    day
  }
}

const CHINESE_DAY = ["周天", "周一", "周二", "周三", "周四", "周五", "周六"]
export const getChineseDay = (day) => {
  return CHINESE_DAY[day]
}

export const uuid = () => {
  let s = [];
  let hexDigits = "0123456789abcdef";
  for (let i = 0; i < 36; i++) {
    s[i] = hexDigits.substr(Math.floor(Math.random() * 0x10), 1);
  }
  s[14] = "4"; // bits 12-15 of the time_hi_and_version field to 0010
  s[19] = hexDigits.substr((s[19] & 0x3) | 0x8, 1); // bits 6-7 of the clock_seq_hi_and_reserved to 01
  s[8] = s[13] = s[18] = s[23] = "-";

  let uuid = s.join("");
  return uuid;
}

export const randomString = (length = 8) => {
  let s = []
  let hexDigits = "0123456789abcdef";
  for (let i = 0; i < length; i++) {
    s[i] = hexDigits.substr(Math.floor(Math.random() * 0x10), 1);
  }
  return s.join("");
}

export const matchRoute = (path, routeList) => {
  let pathList = path.split("/");
  let matchMap = routeList.map(route => {
    let routeList = route.split("/")
    let maxIndex = -1
    for (let index in pathList) {
      if (pathList[index] == routeList[index]) {
        maxIndex = index
      } else {
        break;
      }
    }
    return maxIndex
  })
  let matchMax = Math.max(...matchMap)
  let current = matchMap.findIndex(item => item == matchMax)
  return current
}

export const getStorageObject = (key) => {
  let result = localStorage.getItem(key)
  if (result) {
    result = JSON.parse(result)
    if (Object.prototype.toString.call(result) != "[object Object]") {
      result = {}
    }
  } else {
    result = {}
  }
  return result
}

export const petSexFormatter = (sex) => {
  switch (sex) {
    case 0:
      return "未知";
    case 1:
      return "公";
    case 2:
      return "母";
    case 3:
      return "去势";
    case 4:
      return "绝育";
  }
}

export const petSpeciesFormatter = (species) => {
  switch (species) {
    case 1:
      return "猫";
    case 2:
      return "狗";
    case 3:
      return "其它";
    default:
      return "";
  }
}

export const getCookie = (name) => {
  // 将document.cookie字符串按照; 分割成数组
  var cookies = document.cookie.split(';');
  // 修剪字符串两侧的空格
  name = name + "=";
  for (var i = 0; i < cookies.length; i++) {
    var cookie = cookies[i].trim();
    // 检查当前cookie是否是目标cookie
    if (cookie.indexOf(name) == 0)
      return cookie.substring(name.length, cookie.length);
  }
  return ""; // 如果没有找到，返回空字符串
}

export const setCookie = (obj, time) => {
  let date = new Date(new Date().getTime() + time * 1000 * 60 * 60 * 24).toUTCString();
  for (let key in obj) {
    document.cookie = key + "=" + obj[key] + ";expires=" + date;
  }
}

export const removeCookie = (key) => {
  setCookie({ [key]: "" }, -1)
}

export const browserCopy = (value) => {
  if (document.execCommand) {
    let transfer = document.createElement('input');
    document.body.appendChild(transfer);
    transfer.value = value
    transfer.focus();
    transfer.select();
    document.execCommand('copy');
    transfer.blur();
    document.body.removeChild(transfer);
    vueEl.$message({
      type: 'success',
      message: '复制成功'
    });
  } else {
    vueEl.$message({
      type: 'error',
      message: '复制失败，当前浏览器不支持该功能'
    });
  }
}

export const cities = [
  {
    id: "110000",
    name: "北京",
  }, {
    id: "310000",
    name: "上海",
  }
]