// 本文件对网络请求进行了封装
// 虽然对请求接口401进行了统一处理，但是这个处理有个前提条件：每个页面都进行了网络请求
import axios from 'axios';
import store from '@/store/index';
import router from '@/router/index';
import { setCookie } from "@/apis/index";
import { getCookie } from "@/utils/helpers";
import qs from 'qs';
import Vue from 'vue';
const vueEl = new Vue
if (process.env.NODE_ENV == 'development') {
  axios.defaults.baseURL = '/dev';
} else if (process.env.NODE_ENV == 'test') {
  axios.defaults.baseURL = 'http://113.125.27.177:5000';
} else if (process.env.NODE_ENV == 'production') {
  axios.defaults.baseURL = '';
}

// 请求拦截器
axios.interceptors.request.use(
  config => {
    if (config.method === 'get' || config.method === 'delete') {
      // 如果是get请求，且params是数组类型如arr=[1,2]，则转换成arr=1&arr=2
      config.paramsSerializer = function (params) {
        return qs.stringify(params, { arrayFormat: 'repeat' })
      }
    }
    return config;
  },
  error => {
    return Promise.error(error);
  }
)

// 响应拦截器
axios.interceptors.response.use(
  response => {
    if (response.status === 200) {
      extractToken(response)
      return Promise.resolve(response);
    } else {
      return Promise.reject(response);
    }
  },
  error => {
    if (error.response && error.response.status) {
      return Promise.reject(error.response);
    } else {
      return Promise.reject(error.message)
    }
  }
);

let todoList = []
let isLogining = false
let sameRequestMap = {}

function checkToken(url, config) {
  return new Promise(resolve => {
    let token = getCookie("XSRF-TOKEN");
    if (url == setCookie) {
      resolve()
    } else if (token) {
      if(!config.headers) {
        config.headers = {}
      }
      config.headers["Requestverificationtoken"] = token
      resolve()
    } else {
      get(setCookie).then(() => {
        token = getCookie("XSRF-TOKEN");
        if(!config.headers) {
          config.headers = {}
        }
        config.headers["Requestverificationtoken"] = token
        resolve()
      })
    }
  })
}

function get(url, data, config) {
  let newData = {}
  for (let index in data) {
    newData[index.replace(/_/ig, "")] = data[index]
  }
  return unionRequest(url, null, { ...config, params: newData })
}

function del(url, data, config) {
  let newData = {}
  for (let index in data) {
    newData[index.replace(/_/ig, "")] = data[index]
  }
  return unionRequest(url, null, { ...config, params: newData }, 'delete')
}

function post(url, data, config, params) {
  return unionRequest(url, data, { ...config, params }, 'post');
}

function put(url, data, config) {
  return unionRequest(url, data, config, 'put');
}

function unionRequest(url, data, config={}, method = "get") {
  return new Promise((resolve, reject) => {
    checkToken(url, config).then(() => {
      const objectKey = url + JSON.stringify(data) + JSON.stringify(config)
      if (sameRequestMap[objectKey]) {
        sameRequestMap[objectKey].push([resolve, reject])
        return
      } else {
        sameRequestMap[objectKey] = []
      }
      let promise = null
      if (method == "get" || method == "delete") {
        promise = axios[method](url, config)
      } else {
        promise = axios[method](url, data, config)
      }
      promise.then((res) => {
        resolve(res)
        digestSameRequest(objectKey, res, 0)
      }).catch((err) => {
        if (err.status == 401) {
          todoList.push({ url, data, config, method, resolve, reject })
          if (!isLogining) {
            tryLogin()
          }
        } else if(err.status == 204) {
          resolve(err)
          digestSameRequest(objectKey, err, 0)
        } else {
          let errorMessage = ""
          if (err && err.status == 500) {
            errorMessage = "服务器错误"
          } else {
            if (err.data && err.data.error) {
              const error = err.data.error
              if (error.details) errorMessage = error.details
              else if (error.message) errorMessage = error.message
            } else {
              if (err.status) errorMessage = errorMessage + " " + err.status
              if (err.statusText) errorMessage = errorMessage + " " + err.statusText
            }
            errorMessage = errorMessage ? errorMessage : "网络连接已断开，请检查网络再重新尝试"
          }
          vueEl.$message.error(errorMessage)
          reject({ error: err, message: errorMessage })
          digestSameRequest(objectKey, err, 1)
        }
      })
    })

  })
}

const tryLogin = function () {
  isLogining = true
  tryAccessToken().then(() => {
    isLogining = false
    digestTodoList()
  }).catch(() => {
    tryLoginToken().then(() => {
      isLogining = false
      digestTodoList()
    }).catch(() => {
      tryRefreshToken().then(() => {
        isLogining = false
        digestTodoList()
      }).catch(() => {
        isLogining = false
        router.push("/login")
      })
    })
  })
}

const tryAccessToken = function () {
  return new Promise((resolve, reject) => {
    reject()
    // const newToken = router.app.$route.query.accessToken
    // if (newToken) {
    //   store.commit("user/changeAccessToken", newToken)
    //   localStorage.setItem("doctor.accessToken", newToken)
    //   let item = todoList.pop()
    //   let promise = null
    //   if (item.method == "get" || item.method == "delete") {
    //     promise = axios[item.method](item.url, item.config)
    //   } else {
    //     promise = axios[item.method](item.url, item.data, item.config)
    //   }
    //   promise.then((res) => {
    //     item.resolve(res)
    //     resolve()
    //   }).catch(err => {
    //     if (err.status == 401) {
    //       todoList.unshift(item)
    //       reject()
    //     } else {
    //       item.reject(err)
    //       resolve()
    //     }
    //   })
    // } else {
    //   reject()
    // }
  })
}

const tryLoginToken = function () {
  return new Promise((resolve, reject) => {
    reject()
    // const loginToken = router.app.$route.query.login_token
    // if (loginToken) {
    //   axios.get(token, { params: { loginToken } }).then(res => {
    //     resolve()
    //   }).catch(err => {
    //     reject()
    //   })
    // } else {
    //   reject()
    // }
  })

}

const tryRefreshToken = function () {
  return new Promise((resolve, reject) => {
    reject()
    // const refreshToken = localStorage.getItem('doctor.refreshToken')
    // if (refreshToken) {
    //   axios.post(refresh, {}, { params: { refreshToken } }).then(res => {
    //     resolve()
    //   }).catch(err => {
    //     reject()
    //   })
    // } else {
    //   reject()
    // }
  })

}

const digestTodoList = function () {
  todoList.forEach(item => {
    const { method, url, data, config, resolve, reject } = item
    const objectKey = url + JSON.stringify(data) + JSON.stringify(config)
    let promise = null
    if (method == "get" || method == "delete") {
      promise = axios[method](url, config)
    } else {
      promise = axios[method](url, data, config)
    }
    promise.then((res) => {
      resolve(res)
      digestSameRequest(objectKey, res, 0)
    }).catch(err => {
      reject(err)
      digestSameRequest(objectKey, err, 1)
    })
  })
  todoList = []

}

const digestSameRequest = function (key, res, index) {
  if (sameRequestMap[key]) {
    sameRequestMap[key].forEach(promise => promise[index](JSON.parse(JSON.stringify(res))))
    delete sameRequestMap[key]
  }
}

const extractToken = function (response) {
  if (response && response.data && response.data.result) {
    if (response.data.result.access_token) {
      let newToken = response.data.result.access_token
      store.commit("user/changeAccessToken", newToken)
      localStorage.setItem("doctor.accessToken", newToken)
    }
    if (response.data.result.refresh_token) {
      localStorage.setItem("doctor.refreshToken", response.data.result.refresh_token)
    }
  }
}

const resetParams = function () {
  todoList = []
  isLogining = false
  sameRequestMap = {}
}

export default {
  get,
  post,
  put,
  del,
  resetParams
}