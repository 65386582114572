let Vue
let _vm

class Loading {
  constructor() {
    _vm = new Vue({
      data: {
        $$loading: {
          modules: {},
          actions: {},
        }
      },
    })
  }
  get modules() {
    return _vm._data.$$loading.modules
  }
  get actions() {
    return _vm._data.$$loading.actions
  }
}

const install = function (_Vue) {
  if (Vue && _Vue === Vue) {
    return
  }
  Vue = _Vue
  Vue.mixin({
    beforeCreate() {
      const options = this.$options
      if (options.dvaLoading) {
        this.$dvaLoading = typeof options.dvaLoading === 'function'
          ? options.dvaLoading()
          : options.dvaLoading
      } else if (options.parent && options.parent.$dvaLoading) {
        this.$dvaLoading = options.parent.$dvaLoading
      }
    }
  })
}
export default {
  install,
  Loading
}

export const actionStart = function (nameSpace, actionName) {
  const attrName = nameSpace + "/" + actionName
  Vue.set(_vm._data.$$loading.actions, attrName, true)
  Vue.set(_vm._data.$$loading.modules, nameSpace, true)
}

export const actionEnd = function (nameSpace, actionName) {
  const attrName = nameSpace + "/" + actionName
  Vue.set(_vm._data.$$loading.actions, attrName, false)
  const isModulesEnd = Object.keys(_vm._data.$$loading.actions).some(item => {
    return item.startsWith(nameSpace + "/") && _vm._data.$$loading.actions[item] == true
  })
  Vue.set(_vm._data.$$loading.modules, nameSpace, isModulesEnd)
}
