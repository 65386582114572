import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    redirect: '/main'
  }, {
    path: '/login',
    name: 'login',
    component: () => import('../views/Login.vue'),
  }, {
    path: '/main',
    name: 'main',
    redirect: '/main/appointment',
    component: () => import('../layouts/MainLayout.vue'),
    children: [
      {
        path: 'appointment',
        component: () => import('../views/Appointment.vue'),
      }, {
        path: 'shop',
        component: () => import('../views/Shop.vue'),
      }, {
        path: 'product',
        component: () => import('../views/Product.vue'),
      },
    ]
  }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

export default router
