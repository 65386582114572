import request from '@/utils/request';
import {
  login,
  logout,
  getRoles,
} from "@/apis/index"
import {
  storeDecorator
} from "@/utils/decorator"
import {
  getStorageObject,
  removeCookie,
} from "@/utils/helpers"

export default storeDecorator({
  name: "user",
  state: {
    accessToken: localStorage.getItem("doctor.accessToken"),
    name: localStorage.getItem("doctor.userName"),
    id: localStorage.getItem("doctor.userId"),
    currentShop: localStorage.getItem("doctor.shopId"),
    shops: getStorageObject("doctor.shops"),
  },
  actions: {
    login(_, loginData) {
      return new Promise((resolve, reject) => {
        request.post(login, loginData).then(res => {
          resolve(res)
        }).catch(err => {
          reject(err)
        })
      })
    },
    logout() {
      return new Promise((resolve, reject) => {
        request.get(logout).then(res => {
          removeCookie("XSRF-TOKEN")
          resolve(res)
        }).catch(err => {
          reject(err)
        })
      })
    },
    getRoles() {
      return request.get(getRoles)
    }
  },
})