import Vue from 'vue'
import Vuex from 'vuex'

import appointment from './appointment'
import product from './product'
import shop from './shop'
import user from './user'

Vue.use(Vuex)

export default new Vuex.Store({
  strict: process.env.NODE_ENV !== 'production',
  modules: {
    appointment,
    product,
    shop,
    user,
  }
})
