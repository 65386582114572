import request from '@/utils/request';
import {
  shop,
} from "@/apis/index"
import {
  storeDecorator
} from "@/utils/decorator"

export default storeDecorator({
  name: "shop",
  state: {
    enabledShops: [],
  },
  actions: {
    getShops(_, params) {
      return request.get(shop, params)
    },
    createShop(_, payload) {
      return request.post(shop, payload.params)
    },
    updateShop(_, payload) {
      const url = shop + "/" + payload.id
      return request.put(url, payload.params)
    },
    getEnabledShops({ commit, state }) {
      return new Promise((resolve, reject) => {
        if (state.enabledShops.length) {
          resolve(state.enabledShops)
        } else {
          const params = {
            SkipCount: 0,
            MaxResultCount: 1000,
            Enabled: true
          }
          request.get(shop, params).then(res => {
            if (res.status == 200) {
              const shops = res.data.items
              commit("changeEnabledShops", shops)
              resolve(shops)
            }
          })
        }
      })
    }
  },
})