import request from '@/utils/request';
import {
  appointment,
} from "@/apis/index"
import {
  storeDecorator
} from "@/utils/decorator"

export default storeDecorator({
  name: "appointment",
  actions: {
    getAppointments(_, params) {
      return request.get(appointment, params)
    },
    getAppointment(_, payload) {
      const url = appointment + "/" + payload.id
      return request.get(url, payload.params)
    },
    createAppointment(_, payload) {
      return request.post(appointment, payload.params)
    },
    updateAppointment(_, payload) {
      const url = appointment + "/" + payload.id
      return request.put(url, payload.params)
    }
  },
})