import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import request from './utils/request';
import Loading from './utils/loading';
import TDesign from 'tdesign-vue';
import {
  dateFormat,
  formatterPrice,
  petSexFormatter,
  petSpeciesFormatter,
  cities,
} from "./utils/helpers";

// 引入组件库的少量全局样式变量
import 'tdesign-vue/es/style/index.css';

Vue.use(TDesign);
Vue.use(Loading);
const loadingInstance = new Loading.Loading()
Vue.prototype.request = request

Vue.config.productionTip = false;

Vue.filter('date', function (value, model) {
  if (!value) return '-'
  return dateFormat(new Date(value), model)
})

Vue.filter('price', function (value) {
  if (!value) return '0.00'
  return formatterPrice(value)
})

Vue.filter('species', function (value) {
  return petSpeciesFormatter(value)
})

Vue.filter('petSex', function (value) {
  return petSexFormatter(value)
})

Vue.filter('city', function (value) {
  const index = cities.findIndex(city=>city.id == value)
  if(index != -1) {
    return cities[index].name
  } else {
    return ""
  }
})
new Vue({
  router,
  store,
  dvaLoading: loadingInstance,
  render: (h) => h(App),
}).$mount("#app");
