import { actionStart, actionEnd } from "./loading"
import { firstLetterUpperCase } from "./helpers"
export const storeDecorator = function (options) {
  const { state = {}, mutations = {}, actions = {}, getters = {} } = options
  const defaultMutations = {}
  for (let index in state) {
    defaultMutations["change" + firstLetterUpperCase(index)] = function (currentState, payload) {
      currentState[index] = payload
    }
  }
  let decoratedActions = {}
  for (let index in actions) {
    let func = actions[index]
    decoratedActions[index] = function () {
      return new Promise((resolve, reject) => {
        actionStart(options.name, index)
        func(...arguments).then(resolve).catch(reject).finally(() => {
          actionEnd(options.name, index)
        })
      })
    }
  }
  return {
    namespaced: true,
    state: state,
    getters: getters,
    actions: decoratedActions,
    mutations: {
      ...defaultMutations,
      ...mutations,
    }
  }
}

export const localStorageDecorator = function (options) {
  const { keyMap = {} } = options
  const state = {}
  const mutations = {}
  for (let index in keyMap) {
    const storeKey = keyMap[index]
    mutations["change" + firstLetterUpperCase(index)] = function (currentState, payload) {
      currentState[index] = payload
      localStorage.setItem(storeKey, payload)
    }
    state[index] = localStorage.getItem(storeKey)
  }
  return {
    namespaced: true,
    state: state,
    mutations: mutations
  }
}